<template>
  <v-row>
    <v-col cols="12" v-for="(map, key) in mapping" :key="`incident-fieldset-${key}`">
      <pibot-form-fieldset>
        <template #legend>
          <span class="text-capitalize" v-text="`${key} details`" />
        </template>

        <v-row>
          <v-col
            v-for="(field, i) in map.fields"
            :key="`form-field-${i}`"
            cols="12"
            md="6"
            lg="4"
          >
            <pibot-form-field
              :field="field"
              :loading="loading"
              :readonly="field.readonly || loading"
              :disabled="field.disabled"
              :placeholder="field.label"
              :error-messages="errors[field.model]"
              @change="onChange"
              outlined
            />
          </v-col>
        </v-row>
      </pibot-form-fieldset>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { $gte, $mustBeString, $mustBeNumber } from '@/utils/validations'
import eprs from '@/apps/eprs/utils/mixins/eprs.mixin'
import _ from 'lodash'

const FORM = {
  incident_name: '',
  incident_description: '',
  pipeline_name: '',
  absolute_distance: 0,
  date_of_notification: Date.now(),
  date_of_occurance: Date.now(),
  add_geojson: true,
  demo: 0
}

export default {
  name: 'pibot-eprs-incident',
  components: {
    'pibot-form-fieldset': () => import('@/components/form-components/Fieldset'),
    'pibot-form-field': () => import('@/components/form-components/Field')
  },
  mixins: [eprs, validationMixin],
  validations: {
    form: {
      incident_name: { required, $mustBeString },
      incident_description: { $mustBeString },
      pipeline_name: { required, $mustBeString },
      absolute_distance: { required, $mustBeNumber, $gte: (v) => $gte(v, 0) },
      date_of_occurance: { required, $mustBeNumber, $gte: (v) => $gte(v, 1) },
      demo: {
        required: requiredIf(function () {
          return this.isDemo
        }),
        $mustBeNumber
      }
    }
  },
  data: () => ({
    form: FORM,
    demos: []
  }),
  computed: {
    ...mapState({
      assets: state => state.assets.assetOptions,
      kp: state => state.eprs.kpClick
    }),
    mapping () {
      let mapping = {}

      if (this.isDemo && this.isNew) {
        mapping = {
          demo: {
            label: '',
            fields: [
              {
                type: 'select',
                model: 'demo',
                value: this.form.demo,
                label: 'Demo Scenario *',
                required: true,
                options: this.demos,
                readonly: false
              }
            ]
          }
        }
      }

      mapping = {
        ...mapping,
        asset: {
          label: '',
          fields: [
            {
              type: 'select',
              model: 'pipeline_name',
              value: this.form.pipeline_name,
              label: 'Asset *',
              required: true,
              options: this.assets,
              disabled: this.isDemo
            }
          ]
        },
        incident: {
          label: '',
          fields: [
            {
              type: 'text',
              model: 'incident_name',
              value: this.form.incident_name,
              label: 'Incident Name *',
              required: true
              // readonly: this.isDemo
            },
            {
              type: 'textarea',
              model: 'incident_description',
              value: this.form.incident_description,
              label: 'Incident Description',
              required: false
              // readonly: this.isDemo
            },
            {
              type: 'datetime',
              model: 'date_of_occurance',
              value: this.form.date_of_occurance,
              label: 'Date of Occurance *',
              min: 1,
              required: true
              // disabled: this.isDemo
            },
            {
              type: 'number',
              model: 'absolute_distance',
              value: this.form.absolute_distance,
              label: 'Absolute Distance *',
              suffix: 'm',
              min: 0,
              required: true
              // readonly: !this.isDemo
            }
          ]
        }
      }

      return mapping
    },
    errors () {
      const errors = {}

      for (const map of Object.values(this.mapping)) {
        for (const field of Object.values(map.fields)) {
          if (!this.$v.form[field.model].$invalid) {
            delete errors[field.model]
            continue
          }

          const messages = this.$v.form[field.model].$flattenParams().map(({ name }) => {
            if (name.includes('required')) return `${field.label} is required.`
            else if (name.includes('gte')) return `${field.label} must be greater or equal to ${field.min}.`
            else if (name.includes('mustBeNumber')) return `${field.label} must be a number.`
            else if (name.includes('mustBeString')) return `${field.label} must be text.`
            else return undefined
          }).filter(v => !!v)

          errors[field.model] = messages
        }
      }

      return errors
    },
    isNew () {
      return this.$route.name.includes('new')
    }
  },
  async beforeMount () {
    await this.init()
    if (this.isDemo && this.isNew) this.initDemo()
    this.validate()
  },
  methods: {
    ...mapMutations({
      setKpClick: 'eprs/SET_KP_CLICK'
    }),
    ...mapActions({
      fetchAssets: 'assets/socket_fetchAssetData'
    }),
    async init () {
      this.emptyForm()

      const promises = []

      // Fetch incident data and prefill form
      if (this.iid) promises.push(this.getIncidentById(this.iid))

      // Fetch asset data
      if (!this.assets.length) promises.push(this.fetchAssets())

      // Fill in absolute value from map mouse click and reset
      if (this.kp) {
        this.form.absolute_distance = this.kp
        this.setKpClick(undefined)
      }

      // Fetch data and prefill form
      await Promise.all(promises).then((res) => {
        if (!this.iid) return // No need to prefill form. This is a new incident

        const data = res[0]
        const keys = Object.keys(this.form)

        for (const key of keys) {
          if (data[key]) this.form[key] = data[key]
        }

        // prefill form with incident data

        const assessment = _.last(data.assessments)?.data
        if (!assessment) return

        // prefill form with assessment data
        let prefill = _.filter(assessment, (o) => {
          return keys.includes(o['incoming key'])
        }).map(o => ({ [o['incoming key']]: o.value }))

        prefill = _.merge(...prefill)
        prefill = _.merge(..._.filter(Object.entries(data), ([key]) => keys.includes(key)).map(([key, value]) => ({ [key]: value })))

        this.form = Object.assign({}, this.form, prefill)
      }).catch(error => { console.error(error) })
    },
    initDemo () {
      // Get demo assessments
      const demos = require('@/assets/datasamples/demo_assessments.json')

      // Map demo options
      this.demos = demos.map((d, i) => ({
        text: `Demo scenario ${i + 1}`,
        value: i
      }))

      // Prefill demo options
      this.form = Object.assign({}, this.form, demos[this.form.demo].user_inputs)

      // Set a watcher for demo options to set form values
      this.$watch('form.demo', (option, old) => {
        // Set form to prefilled demo if the selected demo was changed
        if (option !== old) this.form = Object.assign({}, this.form, demos[this.form.demo].user_inputs)
      }, { deep: true })
    },
    onChange (v) {
      this.$set(this.form, v.field.model, v.value)
      this.$v.form[v.field.model].$touch()
    },
    emptyForm () {
      this.form = Object.assign({}, FORM)
    },
    validate () {
      this.$v.$touch()
      return !this.$v.$invalid
    },
    reset () {
      this.$v.$reset()
      if (this.isDemo) this.initDemo()
    }
  },
  watch: {
    form: {
      deep: true,
      handler (newV, oldV) {
        if (this.isNew && (oldV.date_of_occurance || newV.date_of_occurance)) this.form.date_of_notification = newV.date_of_occurance
      }
    }
  }
}
</script>
